<template>
  <div v-if="showModal" class="modal-overlay">
    <div class="modal-container">
      <!-- Loader Overlay -->
      <div v-if="isLoading" class="loader-overlay">
        <div class="spinner"></div>
      </div>

      <div class="matc-dialog-header">
        <span class="matc-dialog-header-title">Add more Credits</span>
      </div>
      <div class="matc-dialog">
        <div class="matc-dialog-content">
          <p><strong>You don't have enough credits</strong></p>
          <p>Please add credits to continue using the application.</p>

          <!-- Amount Selection -->
          <div v-if="!showPaymentForm">
            <div class="credit-options">
              <div
                v-for="option in creditOptions"
                :key="option.value"
                class="credit-option"
                :class="{ selected: selectedCredit === option.value }"
              >
                <input
                  type="radio"
                  :id="'option-' + option.value"
                  :value="option.value"
                  v-model="selectedCredit"
                  class="credit-radio"
                />
                <label :for="'option-' + option.value" class="credit-label">
                  <span class="credit-amount">{{ option.label }}</span>
                </label>
              </div>
            </div>

            <!-- Custom Amount Input -->
            <div
              v-if="selectedCredit === 'custom'"
              class="custom-input-container"
            >
              <input
                type="number"
                v-model.number="customValue"
                class="custom-input"
                placeholder="Enter custom value (min $5)"
                min="5"
                step="0.01"
                @input="validateCustomValue"
              />
            </div>

            <!-- Error Message -->
            <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>

            <!-- Continue Button -->
            <div class="MatcButtonBarDialog button-container">
              <a
                @click="closeModal"
                class="MatcLinkButton"
                v-if="!isLoading"
                >Cancel</a
              >
              <button
                class="MatcButtonDialog"
                :disabled="isProcessing"
                @click="initiatePayment"
              >
                <span v-if="isProcessing" class="spinner"></span>
                <span v-else>Continue</span>
              </button>
            </div>
          </div>

          <!-- Stripe Payment Form -->
          <div v-else class="payment-form-container">
            <div id="payment-element"></div>

            <!-- Payment Buttons -->
            <div class="MatcButtonBarDialog MatcMarginTop">
              <a
                @click="cancelPayment"
                class="MatcLinkButton"
                v-if="!isLoading"
                >Back</a
              >
              <button
                id="confirm"
                :class="['MatcButtonDialog', { loading: isProcessing }]"
                @click="handlePayment"
                :disabled="isProcessing"
              >
                <span v-if="isProcessing" class="spinner"></span>
                <span v-else>Buy {{ formatPrice() }}</span>
              </button>
            </div>

            <!-- Payment Error Message -->
            <p v-if="paymentError" class="error-message">{{ paymentError }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js/pure';
import Services from '../../services/Services';
import { mapActions } from "vuex";

export default {
  name: 'AddCreditsModal',
  props: {
    currentBalance: {
      type: Number,
      required: true,
    },
    creditOptions: {
      type: Array,
      default: () => [
        { label: '$5', value: 5 },
        { label: '$10', value: 10 },
        { label: '$20', value: 20 },
        { label: 'Custom Amount', value: 'custom' },
      ],
    },
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showModal: false,
      selectedCredit: 5,
      customValue: 5,
      errorMessage: '',
      paymentError: '',
      showPaymentForm: false,
      isProcessing: false,
      isLoading: false,
      stripe: null,
      elements: null,
      clientSecret: '',
    };
  },
  computed: {
    formattedBalance() {
      return this.currentBalance
        ? this.currentBalance.toFixed(2)
        : '0.00';
    },
  },
  methods: {
    ...mapActions(["getCredits"]),
    getSelectedAmount() {
      return this.selectedCredit === 'custom'
        ? this.customValue
        : this.selectedCredit;
    },
    isValidAmount() {
      const amount = this.getSelectedAmount();
      return amount >= 5 && !isNaN(amount);
    },
    formatPrice() {
      const amount = this.getSelectedAmount();
      return `$${parseFloat(amount).toFixed(2)}`;
    },
    validateCustomValue() {
      if (this.customValue < 5) {
        this.errorMessage = 'Minimum amount is $5';
      } else {
        this.errorMessage = '';
      }
    },
    async initiatePayment() {
      const amount = this.getSelectedAmount();
      if (!this.isValidAmount()) {
        this.errorMessage = 'Please enter a valid amount (minimum $5).';
        return;
      }
      this.errorMessage = '';
      this.isProcessing = true;

      try {
        const response = await Services.getStripeService().createPaymentIntent(
          this.user,
          amount
        );
        this.clientSecret = response.clientSecret;
        this.showPaymentForm = true;
        this.$nextTick(() => {
          this.setupStripeElements();
        });
      } catch (error) {
        this.errorMessage =
          'Error initiating payment. Please try again.';
        console.error('Error initiating payment:', error);
      } finally {
        this.isProcessing = false;
      }
    },
    async setupStripeElements() {
      if (!this.stripe) {
        const stripeKey = Services.getConfig().stripeKey;
        this.stripe = await loadStripe(stripeKey);
      }

      this.isLoading = true;
      this.elements = this.stripe.elements({
        clientSecret: this.clientSecret,
      });
      const paymentElement = this.elements.create('payment');
      paymentElement.mount('#payment-element');

      paymentElement.on('ready', () => {
        this.isLoading = false;
      });
    },
    async handlePayment() {
      this.isProcessing = true;
      this.paymentError = '';

      const { error } = await this.stripe.confirmPayment({
        elements: this.elements,
        confirmParams: {
          // Optionally, include return_url here
        },
        redirect: 'if_required',
      });

      if (error) {
        this.paymentError = error.message;
        console.error('Payment failed:', error.message);
      } else {
        this.$emit('payment-success');        
        await this.getCredits();
        this.closeModal();
      }

      this.isProcessing = false;
    },
    cancelPayment() {
      this.showPaymentForm = false;
      this.isProcessing = false;
      this.isLoading = false;
      if (this.elements) {
        this.elements = null;
      }
    },
    closeModal() {
      if (!this.isLoading) {
        this.showModal = false;
        // Reset states
        this.showPaymentForm = false;
        this.isProcessing = false;
        this.paymentError = '';
        this.errorMessage = '';
        this.selectedCredit = 5;
        this.customValue = 5;
      }
    },
    show() {
      this.showModal = true;
    },
  },
};
</script>

<style lang="scss">
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-container {
  background: white;
  border-radius: 8px;
  min-width: 600px;
  position: relative;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.loader-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  border: 4px solid rgba(0, 123, 255, 0.2);
  border-top: 4px solid #007bff;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.matc-dialog {
  padding: 25px;
}

.matc-dialog-header {
  padding: 15px;
  border-bottom: 1px solid #d5d5d5;
}

.matc-dialog-header-title {
  font-size: 20px;
}

.MatcButtonBarDialog {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  width: 100%;
  margin-top: 20px;
}

.MatcButtonDialog {
  display: inline-flex;
  padding: 8px 24px;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.25s;
  font-size: 14px;
  font-weight: 400;
  background-color: #007bff;
  border: none;
  color: #fff;
  align-items: center;
}

.MatcButtonDialog:hover {
  background: #0056b3;
}

.MatcLinkButton {
  border-radius: 10px;
  padding: 8px 16px;
  cursor: pointer;
  color: #4a4a4a;
  font-size: 14px;
  font-weight: 700;
  background: #f1f1f1;
  align-items: center;
}

.MatcLinkButton:hover {
  background: #e7e7e7;
  color: #333;
}

.error-message {
  color: red;
  margin-top: 10px;
}
</style>
