import Logger from "../core/Logger";
import JSONPath from "../core/JSONPath";
import RestEngine from "./RestEngine";
import Services from "../../services/Services";

export default class MakeBridgeEngine {
  constructor(conf) {
    if (conf.proxy) {
      this.proxyURL = conf.proxy;
      this.baseURL = "https://eu2.make.com";
      this.makeOrgId = "2409492";
      this.mainTeamId = "985269";
    }
    Logger.log(-12, "MakeBridgeEngine.constructor()", this.proxyURL);
  }

  async run(appID, hash, widget, viewModel, parent) {
    Logger.log(-12, "MakeBridgeEngine.run()", viewModel);
    console.log(parent);

    if (!widget?.props?.api) {
      Logger.error("MakeBridgeEngine.run() > no API");
      return null; // Return early if API is required
    }

    const api = widget.props.api;
    const instanceableId = api.instanceableId || 908;

    const dataapp = await Services.getMakeService(hash).getAppUserData();
    const flow = await this.getFlowDataByTemplateId(dataapp, instanceableId);

    if (flow && flow?.scenarios) {
      let result = await this.executeWorkflow(flow, viewModel, hash, appID, widget);
      if (widget.props.databinding?.default) {
        this.setDataBinding(viewModel, result, widget.props.databinding);
      }
    } else {
      const body = {
        teamId: 1026368,
        templateId: instanceableId,
      };

      let result = await this.initWorkflow(body, viewModel, hash, appID);
      if (result && result.publicUrl && result.flow?.id) {
        if (typeof window !== "undefined") {
          window.open(result.publicUrl, "_blank");
        }
        await this.saveFlowId(result.flow.id, instanceableId, viewModel, hash, appID);
        if (widget.props.databinding?.default) {
          this.setDataBinding(viewModel, result, widget.props.databinding);
        }
      }
    }

    return null;
  }

  setDataBinding(viewModel, value, databinding) {
    JSONPath.set(viewModel, databinding.default, value);
  }

  buildMakeBody(widget) {
    const params = widget.props?.api?.params;
    return params || undefined;
  }

  async executeWorkflow(flow, viewModel, hash, appID, widget) {
    const uri = flow.hooks[0].uri;
    const body = this.buildMakeBody(widget);

    // Check if POST is required for your scenario:
    const request = {
      method: "POST", // Changed to POST assuming webhook expects POST
      authHeader: "Authorization",
      headers: [{ key: "Content-Type", value: "application/json" }],
      output: {
        databinding: "",
        template: "",
        type: "JSON",
        hints: {},
      },
      input: {
        type: "JSON",
        fileDataBinding: "",
        template: body ? JSON.stringify(body) : undefined,
      },
      isProxyEnabled: true,
      url: `${uri}`,
      aiModelId: "make-secret-key",
      isFlowrabbitSecret: true
    };

    const requiredDataBindings = RestEngine.getNeededDataBings(request);
    const data = {};
    requiredDataBindings.forEach((path) => {
      let value = JSONPath.get(viewModel, path);
      data[path] = value;
    });

    let result = await RestEngine.run(request, data, hash, appID, true);
    return result;
  }

  async getFlowDataByTemplateId(data, instanceableId) {
    if (data && data.makeFlows) {
      return data.makeFlows[instanceableId] || null;
    }
    return null;
  }

  async saveFlowId(flowId, instanceableId, data, hash, appID) {
    const makeService = Services.getMakeService(hash);
    const dataapp = await makeService.getAppUserData();

    // Ensure dataapp has the structure: { makeFlows: { ... } }
    if (!dataapp.makeFlows) {
      dataapp.makeFlows = {};
    }

    dataapp.makeFlows[instanceableId] = { flowId: flowId };

    await makeService.updateAppUserData(dataapp);

    let isPolling = true;
    const onVisibilityChange = () => {
      if (typeof document !== "undefined") {
        isPolling = document.visibilityState === "visible";
      }
    };

    if (typeof document !== "undefined") {
      document.addEventListener("visibilitychange", onVisibilityChange);
    }

    let result = null;
    while (!(result && result.flow?.result)) {
      if (isPolling) {
        result = await this.getFlowStatus(flowId, data, hash, appID);
      }
      await new Promise((resolve) => setTimeout(resolve, 5000));
    }

    if (typeof document !== "undefined") {
      document.removeEventListener("visibilitychange", onVisibilityChange);
    }

    // Merge the flow result into the dataapp structure:
    dataapp.makeFlows[instanceableId] = {
      flowId: flowId,
      ...result.flow.result,
    };

    await makeService.updateAppUserData(dataapp);
    console.log(dataapp);
  }

  async getFlowStatus(flowId, viewModel, hash, appID) {
    const request = {
      method: "GET",
      authHeader: "Authorization",
      headers: [{ key: "Content-Type", value: "application/json" }],
      output: {
        databinding: "",
        template: "",
        type: "JSON",
        hints: {},
      },
      input: {
        type: "JSON",
        fileDataBinding: "",
      },
      isProxyEnabled: true,
      url: `${this.baseURL}/api/v2/instances/flow/${flowId}`,
      aiModelId: "make-secret-key",
      isFlowrabbitSecret: true
    };

    const requiredDataBindings = RestEngine.getNeededDataBings(request);
    const data = {};
    requiredDataBindings.forEach((path) => {
      let value = JSONPath.get(viewModel, path);
      data[path] = value;
    });

    let result = await RestEngine.run(request, data, hash, appID, true);
    return result;
  }

  async initWorkflow(body, viewModel, hash, appID) {
    const request = {
      method: "POST",
      authHeader: "Authorization",
      headers: [{ key: "Content-Type", value: "application/json" }],
      output: {
        databinding: "",
        template: "",
        type: "JSON",
        hints: {},
      },
      input: {
        type: "JSON",
        fileDataBinding: "",
        template: JSON.stringify(body),
      },
      isProxyEnabled: true,
      url: `${this.baseURL}/api/v2/instances/flow/init/template`,
      aiModelId: "make-secret-key",
      isFlowrabbitSecret: true
    };

    const requiredDataBindings = RestEngine.getNeededDataBings(request);
    const data = {};
    requiredDataBindings.forEach((path) => {
      let value = JSONPath.get(viewModel, path);
      data[path] = value;
    });

    let result = await RestEngine.run(request, data, hash, appID, true);
    return result;
  }
}
