const cleanPromptString = (prompt) => {
  if (!prompt) return "";
  return prompt.replaceAll(/"/g, "'");
};

const ionos = {
  id: "ionos",
  title: "Ionos",
  logo: "ai-ionos",
  brands: [
    { id: "llm", label: "LLM", logo: "ai-ionos-text" },
    { id: "image", label: "Image", logo: "ai-ionos-image" }
  ],
  models: [
    {
      id: "meta-llama-405b-instruct-fp8",
      name: "Meta Llama 3.1 405B Instruct FP8",
      url: "https://openai.inference.de-txl.ionos.com/v1/chat/completions",
      type: "llm",
      headers: [{ key: "Content-Type", value: "application/json" }],
      elements: [
        {
          type: "TextArea",
          required: true,
          id: "prompt",
          label: "Prompt",
          placeholder: "Enter your message",
          helper: "Enter the message for the assistant to respond to",
        },
        {
          type: "Number",
          id: "max_tokens",
          label: "Max Tokens",
          default: 1000,
          helper: "Maximum number of tokens to generate (Limit: 4096).",
          required: true,
          min: 1,
          max: 4096,
        },
      ],
      advanced: [
        {
          type: "TextArea",
          id: "systemprompt",
          label: "System Prompt",
          placeholder: "Write your system prompt",
          class: "MatcAutoCompleteTextareaXXS",
        },
        {
          default: 0.7,
          min: 0,
          max: 2.0,
          type: "range",
          required: false,
          id: "temperature",
          label: "Temperature",
          helper:
            "Controls randomness. 1.0 is the most random, 0.0 is deterministic.",
          decimals: true,
        },
        {
          default: 1.0,
          min: 0,
          max: 1.0,
          type: "range",
          required: false,
          id: "top_p",
          label: "Top P",
          helper: "An alternative to sampling with temperature.",
          decimals: true,
        },
        {
          default: 0,
          min: -2.0,
          max: 2.0,
          type: "range",
          required: false,
          id: "presence_penalty",
          label: "Presence Penalty",
          helper:
            "Penalizes new tokens based on their presence in the text so far.",
          decimals: true,
        },
        {
          default: 0,
          min: -2.0,
          max: 2.0,
          type: "range",
          required: false,
          id: "frequency_penalty",
          label: "Frequency Penalty",
          helper:
            "Penalizes new tokens based on their frequency in the text so far.",
          decimals: true,
        },
      ],
      method: "POST",
      authType: "Bearer",
      getTemplate: (vars) => ({
        model: "meta-llama/Meta-Llama-3.1-405B-Instruct-FP8",
        messages: [
          {
            role: "system",
            content: cleanPromptString(vars.systemprompt),
          },
          { role: "user", content: cleanPromptString(vars.prompt) },
        ],
        temperature: vars.temperature,
        top_p: vars.top_p,
        max_tokens: vars.max_tokens,
        presence_penalty: vars.presence_penalty,
        frequency_penalty: vars.frequency_penalty,
        stream: vars.stream,
      }),
      getMaxTokens: (vars) => vars.max_tokens,
      output: {
        path: "choices[0].message.content",
        type: "JSON",
        streampath: "choices[0].delta.content",
      },
    },
    {
      id: "meta-llama-70b-instruct",
      name: "Meta Llama 3.1 70B Instruct",
      url: "https://openai.inference.de-txl.ionos.com/v1/chat/completions",
      type: "llm",
      headers: [{ key: "Content-Type", value: "application/json" }],
      elements: [
        // Same as above
        {
          type: "TextArea",
          required: true,
          id: "prompt",
          label: "Prompt",
          placeholder: "Enter your message",
          helper: "Enter the message for the assistant to respond to",
        },
        {
          type: "Number",
          id: "max_tokens",
          label: "Max Tokens",
          default: 1000,
          helper: "Maximum number of tokens to generate (Limit: 4096).",
          required: true,
          min: 1,
          max: 4096,
        },
      ],
      advanced: [
        {
          type: "TextArea",
          id: "systemprompt",
          label: "System Prompt",
          placeholder: "Write your system prompt",
          class: "MatcAutoCompleteTextareaXXS",
        },
        {
          default: 0.7,
          min: 0,
          max: 2.0,
          type: "range",
          required: false,
          id: "temperature",
          label: "Temperature",
          helper:
            "Controls randomness. 1.0 is the most random, 0.0 is deterministic.",
          decimals: true,
        },
        {
          default: 1.0,
          min: 0,
          max: 1.0,
          type: "range",
          required: false,
          id: "top_p",
          label: "Top P",
          helper: "An alternative to sampling with temperature.",
          decimals: true,
        },
        {
          default: 0,
          min: -2.0,
          max: 2.0,
          type: "range",
          required: false,
          id: "presence_penalty",
          label: "Presence Penalty",
          helper:
            "Penalizes new tokens based on their presence in the text so far.",
          decimals: true,
        },
        {
          default: 0,
          min: -2.0,
          max: 2.0,
          type: "range",
          required: false,
          id: "frequency_penalty",
          label: "Frequency Penalty",
          helper:
            "Penalizes new tokens based on their frequency in the text so far.",
          decimals: true,
        },
      ],
      method: "POST",
      authType: "Bearer",
      getTemplate: (vars) => ({
        model: "meta-llama/Meta-Llama-3.1-70B-Instruct",
        messages: [
          { role: "user", content: cleanPromptString(vars.prompt) },
        ],
        temperature: vars.temperature,
        top_p: vars.top_p,
        max_tokens: vars.max_tokens,
        presence_penalty: vars.presence_penalty,
        frequency_penalty: vars.frequency_penalty,
        stream: vars.stream,
      }),
      getMaxTokens: (vars) => vars.max_tokens,
      output: {
        path: "choices[0].message.content",
        type: "JSON",
        streampath: "choices[0].delta.content",
      },
    },
    {
      id: "meta-llama-codellama-13b-instruct-hf",
      name: "Code Llama 13B Instruct HF",
      url: "https://openai.inference.de-txl.ionos.com/v1/chat/completions",
      type: "llm",
      headers: [{ key: "Content-Type", value: "application/json" }],
      elements: [
        // Same as above
        {
          type: "TextArea",
          required: true,
          id: "prompt",
          label: "Prompt",
          placeholder: "Enter your message",
          helper: "Enter the message for the assistant to respond to",
        },
        {
          type: "Number",
          id: "max_tokens",
          label: "Max Tokens",
          default: 1000,
          helper: "Maximum number of tokens to generate (Limit: 4096).",
          required: true,
          min: 1,
          max: 4096,
        },
      ],
      advanced: [
        {
          type: "TextArea",
          id: "systemprompt",
          label: "System Prompt",
          placeholder: "Write your system prompt",
          class: "MatcAutoCompleteTextareaXXS",
        },
        {
          default: 0.7,
          min: 0,
          max: 2.0,
          type: "range",
          required: false,
          id: "temperature",
          label: "Temperature",
          helper:
            "Controls randomness. 1.0 is the most random, 0.0 is deterministic.",
          decimals: true,
        },
        {
          default: 1.0,
          min: 0,
          max: 1.0,
          type: "range",
          required: false,
          id: "top_p",
          label: "Top P",
          helper: "An alternative to sampling with temperature.",
          decimals: true,
        },
        {
          default: 0,
          min: -2.0,
          max: 2.0,
          type: "range",
          required: false,
          id: "presence_penalty",
          label: "Presence Penalty",
          helper:
            "Penalizes new tokens based on their presence in the text so far.",
          decimals: true,
        },
        {
          default: 0,
          min: -2.0,
          max: 2.0,
          type: "range",
          required: false,
          id: "frequency_penalty",
          label: "Frequency Penalty",
          helper:
            "Penalizes new tokens based on their frequency in the text so far.",
          decimals: true,
        },
      ],
      method: "POST",
      authType: "Bearer",
      getTemplate: (vars) => ({
        model: "meta-llama/CodeLlama-13b-Instruct-hf",
        messages: [
          {
            role: "system",
            content: cleanPromptString(vars.systemprompt),
          },
          { role: "user", content: cleanPromptString(vars.prompt) },
        ],
        temperature: vars.temperature,
        top_p: vars.top_p,
        max_tokens: vars.max_tokens,
        presence_penalty: vars.presence_penalty,
        frequency_penalty: vars.frequency_penalty,
        stream: vars.stream,
      }),
      getMaxTokens: (vars) => vars.max_tokens,
      output: {
        path: "choices[0].message.content",
        type: "JSON",
        streampath: "choices[0].delta.content",
      },
    },
    {
      id: "meta-llama-8b-instruct",
      name: "Meta Llama 3.1 8B Instruct",
      url: "https://openai.inference.de-txl.ionos.com/v1/chat/completions",
      type: "llm",
      headers: [{ key: "Content-Type", value: "application/json" }],
      elements: [
        {
          type: "TextArea",
          required: true,
          id: "prompt",
          label: "Prompt",
          placeholder: "Enter your message",
          helper: "Enter the message for the assistant to respond to",
        },
        {
          type: "Number",
          id: "max_tokens",
          label: "Max Tokens",
          default: 1000,
          helper: "Maximum number of tokens to generate (Limit: 4096).",
          required: true,
          min: 1,
          max: 4096,
        },
      ],
      advanced: [
        {
          type: "TextArea",
          id: "systemprompt",
          label: "System Prompt",
          placeholder: "Write your system prompt",
          class: "MatcAutoCompleteTextareaXXS",
        },
        {
          default: 0.7,
          min: 0,
          max: 2.0,
          type: "range",
          required: false,
          id: "temperature",
          label: "Temperature",
          helper:
            "Controls randomness. 1.0 is the most random, 0.0 is deterministic.",
          decimals: true,
        },
        {
          default: 1.0,
          min: 0,
          max: 1.0,
          type: "range",
          required: false,
          id: "top_p",
          label: "Top P",
          helper: "An alternative to sampling with temperature.",
          decimals: true,
        },
        {
          default: 0,
          min: -2.0,
          max: 2.0,
          type: "range",
          required: false,
          id: "presence_penalty",
          label: "Presence Penalty",
          helper:
            "Penalizes new tokens based on their presence in the text so far.",
          decimals: true,
        },
        {
          default: 0,
          min: -2.0,
          max: 2.0,
          type: "range",
          required: false,
          id: "frequency_penalty",
          label: "Frequency Penalty",
          helper:
            "Penalizes new tokens based on their frequency in the text so far.",
          decimals: true,
        },
      ],
      method: "POST",
      authType: "Bearer",
      getTemplate: (vars) => ({
        model: "meta-llama/Meta-Llama-3.1-8B-Instruct",
        messages: [
          {
            role: "system",
            content: cleanPromptString(vars.systemprompt),
          },
          { role: "user", content: cleanPromptString(vars.prompt) },
        ],
        temperature: vars.temperature,
        top_p: vars.top_p,
        max_tokens: vars.max_tokens,
        presence_penalty: vars.presence_penalty,
        frequency_penalty: vars.frequency_penalty,
        stream: vars.stream,
      }),
      getMaxTokens: (vars) => vars.max_tokens,
      output: {
        path: "choices[0].message.content",
        type: "JSON",
        streampath: "choices[0].delta.content",
      },
    },
    {
      id: "mistral-7b-instruct-v0.3",
      name: "Mistral 7B Instruct",
      url: "https://openai.inference.de-txl.ionos.com/v1/chat/completions",
      type: "llm",
      headers: [{ key: "Content-Type", value: "application/json" }],
      elements: [
        // Same as above
        {
          type: "TextArea",
          required: true,
          id: "prompt",
          label: "Prompt",
          placeholder: "Enter your message",
          helper: "Enter the message for the assistant to respond to",
        },
        {
          type: "Number",
          id: "max_tokens",
          label: "Max Tokens",
          default: 1000,
          helper: "Maximum number of tokens to generate (Limit: 4096).",
          required: true,
          min: 1,
          max: 4096,
        },
      ],
      advanced: [
        {
          type: "TextArea",
          id: "systemprompt",
          label: "System Prompt",
          placeholder: "Write your system prompt",
          class: "MatcAutoCompleteTextareaXXS",
        },
        {
          default: 0.7,
          min: 0,
          max: 2.0,
          type: "range",
          required: false,
          id: "temperature",
          label: "Temperature",
          helper:
            "Controls randomness. 1.0 is the most random, 0.0 is deterministic.",
          decimals: true,
        },
        {
          default: 1.0,
          min: 0,
          max: 1.0,
          type: "range",
          required: false,
          id: "top_p",
          label: "Top P",
          helper: "An alternative to sampling with temperature.",
          decimals: true,
        },
        {
          default: 0,
          min: -2.0,
          max: 2.0,
          type: "range",
          required: false,
          id: "presence_penalty",
          label: "Presence Penalty",
          helper:
            "Penalizes new tokens based on their presence in the text so far.",
          decimals: true,
        },
        {
          default: 0,
          min: -2.0,
          max: 2.0,
          type: "range",
          required: false,
          id: "frequency_penalty",
          label: "Frequency Penalty",
          helper:
            "Penalizes new tokens based on their frequency in the text so far.",
          decimals: true,
        },
      ],
      method: "POST",
      authType: "Bearer",
      getTemplate: (vars) => ({
        model: "mistralai/Mistral-7B-Instruct-v0.3",
        messages: [
          {
            role: "system",
            content: cleanPromptString(vars.systemprompt),
          },
          { role: "user", content: cleanPromptString(vars.prompt) },
        ],
        temperature: vars.temperature,
        top_p: vars.top_p,
        max_tokens: vars.max_tokens,
        presence_penalty: vars.presence_penalty,
        frequency_penalty: vars.frequency_penalty,
        stream: vars.stream,
      }),
      getMaxTokens: (vars) => vars.max_tokens,
      output: {
        path: "choices[0].message.content",
        type: "JSON",
        streampath: "choices[0].delta.content",
      },
    },
    {
      id: "mixtral-8x7b-instruct-v0.1",
      name: "Mixtral 8x7B Instruct",
      url: "https://openai.inference.de-txl.ionos.com/v1/chat/completions",
      type: "llm",
      headers: [{ key: "Content-Type", value: "application/json" }],
      elements: [
        // Same as above
        {
          type: "TextArea",
          required: true,
          id: "prompt",
          label: "Prompt",
          placeholder: "Enter your message",
          helper: "Enter the message for the assistant to respond to",
        },
        {
          type: "Number",
          id: "max_tokens",
          label: "Max Tokens",
          default: 1000,
          helper: "Maximum number of tokens to generate (Limit: 4096).",
          required: true,
          min: 1,
          max: 4096,
        },
      ],
      advanced: [
        {
          type: "TextArea",
          id: "systemprompt",
          label: "System Prompt",
          placeholder: "Write your system prompt",
          class: "MatcAutoCompleteTextareaXXS",
        },
        {
          default: 0.7,
          min: 0,
          max: 2.0,
          type: "range",
          required: false,
          id: "temperature",
          label: "Temperature",
          helper:
            "Controls randomness. 1.0 is the most random, 0.0 is deterministic.",
          decimals: true,
        },
        {
          default: 1.0,
          min: 0,
          max: 1.0,
          type: "range",
          required: false,
          id: "top_p",
          label: "Top P",
          helper: "An alternative to sampling with temperature.",
          decimals: true,
        },
        {
          default: 0,
          min: -2.0,
          max: 2.0,
          type: "range",
          required: false,
          id: "presence_penalty",
          label: "Presence Penalty",
          helper:
            "Penalizes new tokens based on their presence in the text so far.",
          decimals: true,
        },
        {
          default: 0,
          min: -2.0,
          max: 2.0,
          type: "range",
          required: false,
          id: "frequency_penalty",
          label: "Frequency Penalty",
          helper:
            "Penalizes new tokens based on their frequency in the text so far.",
          decimals: true,
        },
      ],
      method: "POST",
      authType: "Bearer",
      getTemplate: (vars) => ({
        model: "mistralai/Mixtral-8x7B-Instruct-v0.1",
        messages: [
          {
            role: "system",
            content: cleanPromptString(vars.systemprompt),
          },
          { role: "user", content: cleanPromptString(vars.prompt) },
        ],
        temperature: vars.temperature,
        top_p: vars.top_p,
        max_tokens: vars.max_tokens,
        presence_penalty: vars.presence_penalty,
        frequency_penalty: vars.frequency_penalty,
        stream: vars.stream,
      }),
      getMaxTokens: (vars) => vars.max_tokens,
      output: {
        path: "choices[0].message.content",
        type: "JSON",
        streampath: "choices[0].delta.content",
      },
    },
    {
      id: "flux-1-schnell",
      name: "FLUX 1 Schnell",
      url: "https://openai.inference.de-txl.ionos.com/v1/images/generations",
      type: "image",
      headers: [{ key: "Content-Type", value: "application/json" }],
      elements: [
        {
          type: "TextArea",
          required: true,
          id: "prompt",
          label: "Prompt",
          placeholder: "Describe the image you want to generate",
          helper: "The prompt to generate images from",
        },
        {
          type: "Number",
          id: "n",
          label: "Number of Images",
          default: 1,
          helper: "The number of images to generate. Defaults to 1.",
          required: false,
          min: 1,
          max: 10,
        },
        {
          type: "Select",
          id: "size",
          label: "Image Size",
          default: "1024*1024",
          options: ["512*512", "1024*1024", "1792*1024"],
          helper:
            "The size of the image to generate. Maximum is '1792*1024'.",
        },
      ],
      advanced: [
        {
          default: "b64_json",
          type: "hidden",
          required: false,
          id: "response_format",
          label: "Response Format",
          helper: "The format of the response.",
        },
      ],
      method: "POST",
      authType: "Bearer",
      getTemplate: (vars) => ({
        model: "black-forest-labs/FLUX.1-schnell",
        prompt: cleanPromptString(vars.prompt),
        n: vars.n,
        size: vars.size,
        response_format: vars.response_format,
        user: vars.user,
      }),
      output: {
        path: "data[0].b64_json",
        type: "JSON",
        formatType: "base64",
      },
    },
    {
      id: "stable-diffusion-xl-base-1.0",
      name: "Stable Diffusion XL Base 1.0",
      url: "https://openai.inference.de-txl.ionos.com/v1/images/generations",
      type: "image",
      headers: [{ key: "Content-Type", value: "application/json" }],
      elements: [
        {
          type: "TextArea",
          required: true,
          id: "prompt",
          label: "Prompt",
          placeholder: "Describe the image you want to generate",
          helper: "The prompt to generate images from",
        },
        {
          type: "Number",
          id: "n",
          label: "Number of Images",
          default: 1,
          helper: "The number of images to generate. Defaults to 1.",
          required: false,
          min: 1,
          max: 10,
        },
        {
          type: "Select",
          id: "size",
          label: "Image Size",
          default: "1024*1024",
          options: ["512*512", "1024*1024", "1792*1024"],
          helper:
            "The size of the image to generate. Maximum is '1792*1024'.",
        },
      ],
      advanced: [
        {
          default: "b64_json",
          type: "hidden",
          required: false,
          id: "response_format",
          label: "Response Format",
          helper: "The format of the response.",
        },
      ],
      method: "POST",
      authType: "Bearer",
      getTemplate: (vars) => ({
        model: "stabilityai/stable-diffusion-xl-base-1.0",
        prompt: cleanPromptString(vars.prompt),
        n: vars.n,
        size: vars.size,
        response_format: vars.response_format,
        user: vars.user,
      }),
      output: {
        path: "data[0].b64_json",
        type: "JSON",
        formatType: "base64",
      },
    },
  ],
};

export default ionos;
